.ingredients-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
  width: 100%;
  gap: 10px;
  height: calc(100vh - 520px);
  // border: 1px solid red;
  overflow-y: auto;
  flex-wrap: unset;
  padding-right: 5px;
  h4 {
    color: #243d4c;
    font-family: "Poppins" !important;
    font-size: 20px;
    font-weight: 600;
  }
  @media only screen and (max-width: 960px) {
    height: unset;
    overflow-y: visible;
  }
}

.ingredients-wrapper::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
.ingredients-wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.ingredients-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #40b9b6;
}
