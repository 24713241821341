.sidebar-wrapper {
  width: 28%;
  margin-top: 80px;
  position: fixed;
  left: 0;
  margin-left: 15px;
  border: 1px solid transparent;
  border-radius: 20px;
  height: 85vh;
  overflow-y: scroll;
  background: #fff;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    display: none;
  }
  @media only screen and (max-width: 1130px) {
    margin-left: 8px;
  }
  .sidebar-options-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_footer {
    padding: 10px;
    &-recipe {
      background: rgba(61, 183, 108, 0.01);
      border: 1px solid #40b9b6;
      border-radius: 10px;
      width: 100%;
      padding: 5px;
      cursor: pointer;
    }

    &-ingredient {
      background: #40b9b6;
      border: 1px solid #40b9b6;
      border-radius: 10px;
      width: 100%;
      color: #fff;
      margin-top: 10px;
      padding: 5px;
      cursor: pointer;
    }
  }
}
.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}
