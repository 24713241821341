@use "../../master.scss";
@use "../../variables.scss";

.modal {
  border-radius: 25px;
  overflow: hidden;

  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    display: block;
  }
  .PhoneInput {
    padding: 6.5px 11px;
    font-size: 16px;
    background: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    position: relative;
    width: 100%;
    border-radius: 8px;
    input {
      user-select: none;
      border: none;
    }
  }
  .ant-form-item-has-error .PhoneInput {
    background: #fff;
    border-color: #ff4d4f;
  }

  .modal-footer {
    text-align: right;
    margin-top: 20px;

    button {
      display: inline-block;
      margin: 5px;
      background: master.$theme-color;
      color: #fff;
      font-size: 13px;
      padding: 6px 25px;
      border-radius: 5px;
      border: 1px solid master.$theme-color;
      cursor: pointer;
      transition: 0.4s all;

      &:hover {
        background: master.$theme2-color;
        border: 1px solid master.$theme2-color;
      }

      &.close {
        background: transparent;
        border: 1px solid master.$theme-color;
        color: master.$theme-color;

        &:hover {
          border: 1px solid master.$theme-color;
        }
      }
    }
  }

  //   ***************************

  .modal-wrapper {
    .login-wrapper {
      &_title {
        // font-family: variables.$theme2-family-dmsans !;
        font-size: 24px;
        color: #170f49;
      }
      &_desc {
        color: #6f6c90 !important;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #d9dbe9 !important;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      .login-rows-wrapper {
        .email-wrapper {
          &_label {
            color: #170f49;
            font-size: 15px;
            font-weight: 400;
          }
          &_input {
            border-radius: 8px;

            .ant-input:focus {
              // border: none !important;
              border-color: #d9dbe9 !important;
              box-shadow: none !important;
            }
          }
          .ant-input:hover {
            border-color: #ecf8f0 !important;
          }
          .ant-input:focus {
            border-color: #d9dbe9 !important;
            // box-shadow: 0 0 0 1px #59b76d !important;
          }
        }
        .password-wrapper {
          &_label {
            color: #170f49;
            font-size: 15px;
            font-weight: 400;
          }
          &_input {
            border-radius: 8px;
          }
          .ant-input-affix-wrapper-focused {
            border-color: #d9dbe9 !important;
            box-shadow: 0 0 0 1px #59b76d !important;
          }
          .ant-input-affix-wrapper:hover {
            border-color: #ecf8f0 !important;
          }
        }
        .forgot-pass-wrapper {
          h6 {
            color: #6f6c90;
            font-size: 10px;
            font-weight: 400;
          }
        }
        .btn-login {
          margin-top: 15px;
          margin-bottom: 5px;
          width: 100% !important;
        }
        .dont-have-acc {
          display: flex;
          justify-content: center;
          strong {
            color: #170f49;
            font-size: 14px;
            font-weight: 400;
          }
          b {
            color: #170f49;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
