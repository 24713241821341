@use "../../master.scss";
@use "../../variables.scss";

.suggest-ingredients-modal {
  border-radius: 25px;
  overflow: hidden;

  ul {
    margin-left: 2.5em;
    margin-top: 10px;
  }
  li {
    span {
      color: #243d4c;
      font-size: 14px;
    }
  }
  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 30px;
      position: relative;
      min-height: 500px;
      @media only screen and (max-width: 960px) {
        max-height: 300px;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      border-color: transparent;
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .ant-row {
      width: 85%;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .title {
    font-size: 20px;
    color: #243d4c;
    margin-bottom: 2px;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;
  }
  .suggestion-btn-container {
    border-top: 1px solid #d9dbe9;
    padding-top: 20px;
    width: 80% !important;
    margin: 0;
  }
  .next-modal-btn {
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
    border-radius: 10px;
    border-color: #40b9b6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #40b9b6;
    padding: 20px 20px;
  }
  .back-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
    border-radius: 10px;
    border-color: #40b9b6;
    color: #40b9b6;
    background-color: transparent;
    padding: 20px 20px;
  }
  .back-modal-btn:hover,
  .back-modal-btn:focus {
    background-color: transparent;
    color: #40b9b6;
    border-color: #40b9b6;
  }
  .next-modal-btn:hover,
  .next-modal-btn:focus {
    background-color: #40b9b6;
    color: #fff;
    border-color: #40b9b6;
  }
  .next-modal-btn:hover {
  }
  .del-icon-wrapper {
    border: 1.5px solid #fff;
    background-color: #40b9b6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .added-suggestion {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;
    min-height: 100px;
    @media only screen and (max-width: 960px) {
      max-height: 126px;
      overflow-y: auto;
    }
  }
  .added-item-wrapper {
    padding: 5px;
    position: relative;
    height: fit-content;
  }
  .added-item {
    background-color: #40b9b6;
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 12px;
    p {
      margin: 0;
      font-size: 14px;
      color: #fff;
    }
  }
  .suggestion-content-wrapper {
    border-top: 1px solid #d9dbe9;
    padding-top: 25px;
    margin-top: 23px;
    h4 {
      font-size: 24px;
      color: #170f49;
      font-weight: 600;
      margin: 0;
    }
    p {
      color: #6f6c90;
      font-size: 14px;
    }
  }
  .ant-input {
    padding: 10px 15px;
    border-radius: 12px;
    font-size: 16px;
  }
  .ant-input::placeholder {
    font-family: "Poppins" !important;
    font-size: 16px;
  }
  .ant-input:hover {
    border-color: #40b9b6;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #40b9b6;
  }
  .ant-steps-item-title::after {
    width: 90px;
    height: 10px;
    border-radius: 10px;
    top: 10px;
    background-color: #40b9b6 !important;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #40b9b6;
    border-color: #40b9b6;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    font-family: "Helvetica" !important;
    font-size: 14px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #6f6c90;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #40b9b6;
    border-color: #40b9b6;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-vertical {
    flex-direction: row;
  }
  .meals-options {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    @media only screen and (max-width: 960px) {
      max-height: 226px;
      overflow-y: auto;
    }
    // min-height: 100px;
    .meals-options-items {
      background-color: #f0f2f4;
      border-radius: 20px;
      padding: 5px 15px;
      width: max-content;
      height: fit-content;
      cursor: pointer;
      span {
        font-size: 12px;
        font-family: "Poppins" !important;
        color: #788796;
      }
    }
    .meals-options-items-active {
      background-color: #40b9b6;
      span {
        color: #fff;
      }
    }
    .meals-options-selected {
      width: 100%;
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }
  }
  .confirm-info {
    background: #fff;
    border: 1px solid rgba(36, 61, 76, 0.2);
    border-radius: 15px;
    padding: 20px;
    @media only screen and (max-width: 960px) {
      max-height: 246px;
      overflow-y: auto;
      width: 100% !important;
    }
  }
}
