@use "../../master.scss";
@use "../../variables.scss";

.create-collection-modal {
  border-radius: 25px;
  overflow: hidden;

  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 30px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .ant-row {
      width: 100%;
    }
    .ant-upload {
      width: 100%;
    }

    .file-upload-fav-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      border: 2px dashed #40b9b6;

      padding: 10px;
      .img-upload {
        width: 50px;
        height: 50px;
      }
      border-radius: 15px;
      color: #40b9b6;
      font-size: x-large;
      font-weight: bold;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .title {
    font-size: 20px;
    color: #243d4c;
    margin-bottom: 2px;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;
  }
  .collection-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(36, 61, 76, 0.2);
    border-radius: 12px;
    .collection-option-image {
      width: 20%;
    }
    .collection-option-info {
      h4 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        color: #243d4c;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #243d4c;
      }
    }
  }
  .collection-option-active {
    border: 2px solid #40b9b6;
  }
  .create-btn-wrapper {
    margin-top: 20px;
    padding: 0 30px !important;
  }
  .react-ripples {
    width: 100%;
  }
  .create-modal-btn {
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
  }
  .ant-input {
    padding: 10px 15px;
    border-radius: 12px;
    font-size: 16px;
  }
  .ant-input::placeholder {
    font-family: "Poppins" !important;
    font-size: 16px;
  }
  .ant-input:hover {
    border-color: #40b9b6;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #40b9b6;
  }
}
