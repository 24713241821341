.pantry-card-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(36, 61, 76, 0.1);
  border-radius: 16px;
  .card-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 15px;
    border-bottom: 1px solid rgba(36, 61, 76, 0.1);

    h4 {
      color: #243d4c;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
    > img {
      max-width: 30px;
    }
  }
  .card-body {
    padding: 10px 15px;
    .pantry-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      p {
        color: #243d4c;
        margin: 0;
        font-size: 16px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        img {
          cursor: pointer;
        }
      }
    }
  }
}
