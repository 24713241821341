.sidebar-search-wrapper {
  background-color: #40b9b6;
  padding: 20px;
  border-radius: 20px;

  p {
    font-size: 20px;
    font-family: "Poppins" !important;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }
  .ant-select {
    width: 100%;
    .ant-select-clear {
      margin-right: 40px;
      margin-top: -10px !important;
    }
    .ant-select-selector {
      border-radius: 10px !important;
      min-height: 50px;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      font-family: "Poppins" !important;
      font-size: 16px;
      font-weight: 400;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select:hover,
    .ant-select-selector:hover,
    .ant-select-selector:focus {
      border-color: #40b9b6 !important;
    }
  }
}
