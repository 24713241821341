.menu-drawer {
  .ant-drawer-content {
    @media only screen and (max-width: 400px) {
      width: 85% !important;
    }

    @media only screen and (max-width: 320px) {
      width: 70% !important;
    }

    .ant-drawer-body {
      @media only screen and (max-width: 400px) {
        padding: 10px;
      }
    }
    .ant-drawer-header {
      @media only screen and (max-width: 400px) {
        padding: 16px 10px;
      }
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    span {
      font-family: "Poppins" !important;
      font-size: 16px;
    }
    .user-name-image {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  .drawer-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    border-top: 1px solid #e2e3e3;
    border-bottom: 1px solid #e2e3e3;
    padding: 10px 0;
    .user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
    }
    span {
      font-family: "Poppins" !important;
      font-size: 16px;
    }
  }
  .drawer-btn-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    .ant-btn-primary,
    .ant-btn {
      padding: 19px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-weight: 500;
      background-color: #40b9b6;
      border-color: #40b9b6;
      border-radius: 10px;
      span {
        font-family: "Poppins" !important;
        line-height: 24px;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background: #40b9b6 !important;
      border-color: #40b9b6;
    }
  }
  .drawer-header {
    display: flex;
    justify-content: space-between;
  }
  .login-btn {
    background-color: transparent !important;
    color: #40b9b6 !important;
    font-weight: 500 !important;
  }
  .auth-btn {
    padding: 19px 50px;
    margin-bottom: 10px;
  }
  .login-btn:hover,
  .login-btn:focus {
    color: #40b9b6;
    border-color: #40b9b6;
  }
}
