.ingredient-card-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(36, 61, 76, 0.1);
  padding: 10px;
  border-radius: 12px;
  .card-top {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(36, 61, 76, 0.1);
    margin-bottom: 10px;
    .top-info {
      h4 {
        color: #243d4c;
        margin: 0;
        font-size: 16px;
        font-family: "Poppins" !important;
      }
      p {
        color: #788796;
        margin: 0;
        font-size: 12px;
        font-family: "Poppins" !important;
      }
    }
  }
  .item-options {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    .item-options-items {
      background-color: #f0f2f4;
      border-radius: 20px;
      padding: 5px 11px;
      width: max-content;
      text-transform: lowercase;
      cursor: pointer;
      span {
        font-size: 12px;
        font-family: "Poppins" !important;
        color: #788796;
      }
    }
    .item-options-items-active {
      background-color: #40b9b6;
      span {
        color: #fff;
      }
    }
  }
}
