.details-popover-wrapper {
  @media only screen and (min-width: 767px) {
    // background: rgba(61, 183, 108, 0.1);
    background: #40b9b614;
    padding: 20px;
    border-radius: 20px;
  }
  .main-img {
    width: 100%;
    border-radius: 6px;
    height: auto;
    min-height: 100px;
  }
  .pop-recipee-details {
    transition: all 0.8s;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    // @media only screen and (max-width: 960px) {
    //   flex-direction: column;
    // }
    .pop-over-title {
      font-size: 20px;
      color: #243d4c;
      font-weight: 500;
      font-family: "Poppins" !important;
      margin: 0;
    }
    .subtitle {
      color: #243d4c;
      opacity: 0.7;
      font-size: 12px;
      font-family: "Poppins" !important;
      margin-left: 10px;
    }
  }
  .popover-rating {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    p {
      display: flex;
      margin: 0;
    }
    .title {
      font-size: 17px;
      color: #243d4c;
      font-weight: 700;
      font-family: "Poppins" !important;
      margin: 0;
    }
    .subtitle {
      color: #243d4c;
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins" !important;
    }
    .rating-text {
      font-size: 14px;
      color: #243d4c;
      font-weight: 400;
      font-family: "Poppins" !important;
    }
  }
  .rating-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      color: #40b9b6;
      font-size: 13px;
      font-family: "Poppins" !important;
      margin: 0;
      font-weight: 500;
    }
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
  .popover-ingredients {
    h4 {
      font-size: 20px;
      color: #243d4c;
      font-weight: 500;
      font-family: "Poppins" !important;
      margin: 0;
    }
    .ingredients-list {
      margin-left: 2em;
      @media only screen and (min-width: 960px) {
        margin-left: 0.2em;
        padding-left: 2em;
        max-height: 200px;
        height: 100%;
        overflow-y: auto;
      }
      // width: 100%;
      // list-style-type: circle;
    }
    .ingredients-list::-webkit-scrollbar {
      display: none;
    }

    li {
      // list-style-position: inside;
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: "Poppins" !important;
        font-size: 400;
        color: #243d4c;
      }
    }
  }
  .popover-heart-icon {
    width: 20px;
    height: 18px;
    margin-top: 10px;
  }
  .view-recipe-btn {
    min-height: 46px;
  }

  .view-recipe-btn-disabled {
    background-color: rgba($color: #40b9b6, $alpha: 0.5) !important;
  }
  .view-recipe-btn-disabled:hover,
  .view-recipe-btn-disabled:focus {
    background: rgba($color: #40b9b6, $alpha: 0.5) !important;
    border-color: rgba($color: #40b9b6, $alpha: 0.5) !important;
  }

  .ant-btn-primary,
  .ant-btn {
    width: 100%;
    padding: 19px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    font-weight: 500;
    background-color: #40b9b6;
    border-color: #40b9b6;
    border-radius: 10px;
    span {
      font-family: "Poppins" !important;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #40b9b6;
    border-color: #40b9b6;
  }
  .share-btn {
    background-color: transparent !important;
    padding: 19px 8px;
  }
  .share-btn:hover,
  .share-btn:focus {
    color: #40b9b6;
    border-color: #40b9b6;
  }
}
