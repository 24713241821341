@use "../../master.scss";

.main-header {
  width: 100%;
  padding: 20px 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  @media only screen and (max-width: 960px) {
    padding: 12px 10px;
  }

  > img {
    max-width: 106px;
  }

  .ant-btn-primary,
  .ant-btn {
    padding: 19px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    font-weight: 500;
    background-color: #40b9b6;
    border-color: #40b9b6;
    border-radius: 10px;
    span {
      font-family: "Poppins" !important;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #40b9b6 !important;
    border-color: #40b9b6;
  }
  .btn-path {
    background-color: #6c8898;
    border-color: #6c8898;
  }
  .btn-path:hover,
  .btn-path:focus {
    background: #6c8898 !important;
    border-color: #6c8898;
  }
  p {
    font-family: "Poppins" !important;
  }
  .header-btn-cont {
    display: flex;
    align-items: center;
    gap: 15px;
    @media only screen and (max-width: 960px) {
      display: none;
    }
    p {
      margin: 0;
      cursor: default;
    }
    .login-btn {
      background-color: transparent !important;
      color: #40b9b6 !important;
      font-weight: 500 !important;
    }
    .auth-btn {
      padding: 19px 50px;
    }
    .login-btn:hover,
    .login-btn:focus {
      color: #40b9b6;
      border-color: #40b9b6;
    }
    .header-btn {
      font-weight: 500 !important;
    }
    .heart-icon {
      min-height: 22px;
      min-width: 24px;
      cursor: pointer;
    }
    .header-user-name {
      display: flex;
      align-items: center;
      gap: 10px;
      .user-image {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 40px;
        width: 40px;
        img {
          border-radius: 100%;
        }
      }
      .user-name {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        p {
          margin: 0;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }
  .menu-icon-cont {
    display: none;
    @media only screen and (max-width: 960px) {
      display: unset;
    }
    img {
      cursor: pointer;
    }
  }
}
.popover-content {
  .popover-options {
    padding: 5px 25px 5px 5px;
    margin: 0;
    cursor: pointer !important;
  }
  .popover-options:first-of-type {
    border-bottom: 1px solid rgba(36, 61, 76, 0.2);
  }
}

.ant-popover-inner {
  border-radius: 10px !important;
}
