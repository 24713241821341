.fav-loader {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.fav-details-wrapper {
  width: 100%;
  padding: 35px 40px;
  background: #fff;
  position: fixed;
  margin-top: 100px;

  right: 0;
  left: 0;
  height: 85vh;
  border-radius: 20px;
  overflow-y: auto;
  @media only screen and (max-width: 960px) {
    width: 100%;
    position: unset;
  }
  @media only screen and (max-width: 960px) {
    height: 100vh;
    margin-top: 0;
    padding: 85px 15px;
    border-radius: 0;
  }
  .sub-head {
    color: #243d4c;
    font-size: 35px;
    margin: 0;
    font-weight: 600;
  }
  .top-row {
    border-bottom: 1px solid rgba(36, 61, 76, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
    .ant-btn-primary,
    .ant-btn {
      width: 100%;
      padding: 19px 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      font-weight: 500;
      background-color: #40b9b6;
      border-color: #40b9b6;
      border-radius: 10px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      span {
        font-family: "Poppins" !important;
        line-height: 24px;
        font-weight: 500;
      }
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background: #40b9b6 !important;
      border-color: #40b9b6;
    }
  }
  .title {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 960px) {
      margin-bottom: 20px;
    }
    img {
      cursor: pointer;
    }
    h4 {
      font-size: 35px;
      font-family: "Poppins" !important;
      font-weight: 600;
      color: #243d4c;
      margin: 0;
    }
  }
  .content-filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #243d4c;
    img {
      width: 29px;
      height: 30px;
      cursor: pointer;
    }
  }
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 10px !important;
      min-height: 50px;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      font-family: "Poppins" !important;
      font-size: 16px;
      font-weight: 400;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select:hover,
    .ant-select-selector:hover,
    .ant-select-selector:focus {
      border-color: #40b9b6 !important;
    }
    .ant-select-arrow {
      color: #243d4c;
    }
  }
  .ant-btn-primary,
  .ant-btn {
    padding: 19px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    font-weight: 500;
    background-color: #40b9b6;
    border-color: #40b9b6;
    border-radius: 10px;
    span {
      font-family: "Poppins" !important;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #40b9b6;
    border-color: #40b9b6;
  }
  .ant-radio-group {
    background-color: #f6fafd;
    border-radius: 10px;
    width: 40% !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  .ant-radio-button-wrapper {
    background-color: #f6fafd;
    border-color: transparent !important;
    border-radius: 10px !important;
    color: rgba(36, 61, 76, 0.5);
    min-height: 45px;
    width: 50%;
    span {
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins" !important;
      display: flex;
      width: 100%;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #40b9b6;
    background: #40b9b6;
  }
  .ant-radio-button-wrapper:hover {
    color: rgba(36, 61, 76, 0.9);
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):active {
    border-color: #40b9b6;
    background: #40b9b6;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    border-color: #40b9b6;
    background: #40b9b6;
  }
  .tab-row {
    padding: 15px 0;
  }
  .content-popover {
    position: relative;
    padding: 10px;
    // @media only screen and (max-width: 960px) {
    //   display: none;
    // }
    .popover-cancel {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  .responsive-1 {
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .responsive-2 {
    display: none;
    @media only screen and (max-width: 991px) {
      display: inline !important;
    }
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
  .responsive-3 {
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }
}
.my-recipe-wrapper::-webkit-scrollbar {
  display: none;
}

.content-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #243d4c;
  img {
    width: 29px;
    height: 30px;
    cursor: pointer;
  }

  &_inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 465px !important;
    width: 100% !important;

    &-input {
      background: #ffffff;
      border: 1px solid rgba(36, 61, 76, 0.2) !important;
      border-radius: 10px;
      width: 100% !important;
      padding: 17px 25px !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px !important;
      color: #243d4c;
      outline: none !important;
      border-radius: 10px !important;

      &:active {
        border: 1px solid rgba(36, 61, 76, 0.2) !important;
        outline: none !important;
      }

      &:hover {
        border: 1px solid rgba(36, 61, 76, 0.2) !important;
        outline: none !important;
      }

      &:focus {
        border: 1px solid rgba(36, 61, 76, 0.2) !important;
        outline: none !important;
      }
    }

    img {
      position: absolute;
      right: 10px;
    }
  }
}
