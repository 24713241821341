.pantry-drawer {
  .ant-drawer-header {
    border: none;
  }
  .ant-drawer-content-wrapper {
    min-height: 450px;
  }
  .ant-drawer-content-wrapper,
  .ant-drawer-content {
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
  }
  .pantry-drawer-heading {
    text-align: center;
    color: #243d4c;
    font-size: 35px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(36, 61, 76, 0.1);
  }

  .ant-drawer-body::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }
  .ant-drawer-body::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  .ant-drawer-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #40b9b6;
  }
  .MuiMasonry-root {
    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }
}

.clearRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &_btn {
    color: #40b9b6;
    cursor: pointer;
  }

  &_btn-disabled {
    color: lightgray;
  }
}
