.food-card-wrapper {
  padding: 6px;
  border: 1px solid rgba(36, 61, 76, 0.1);
  border-radius: 12px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  // min-height: 317px;
  .food-card-image {
    width: 100%;
    max-height: 300px;
    // height: 100%;
    border-radius: 6px;
    // background: url(../../assets/images/Placeholder.svg) no-repeat;
    // background-size: 80px 80px;
    // width: 100%;
    // height: 100%;
    // min-width: 100px;
    // min-height: 100px;
    // object-fit: cover;
    object-fit: contain;
  }
  .btn-wrapper {
    border-top: 1px solid rgba(36, 61, 76, 0.1);
    padding-top: 13px;
    margin-top: 5px;
  }
  .food-card-info {
    h5 {
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins" !important;
      margin: 0;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    p {
      color: rgba(36, 61, 76, 0.7);
      font-size: 12px;
      font-family: "Poppins" !important;
      margin: 0;
    }
  }
  .card-heart {
    min-width: 18px;
    min-height: 16px;
    margin-top: 5px;
    cursor: pointer;
  }
  .del-btn {
    padding: 19px 7px !important;
    background-color: #ee6464 !important;
    border-color: #ee6464 !important;
  }
  .del-btn:hover,
  .del-btn:focus {
    background: #ee6464 !important;
    border-color: #ee6464 !important;
  }
  .ant-btn-primary,
  .ant-btn {
    width: 100%;
    padding: 19px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    font-weight: 500;
    background-color: #40b9b6;
    border-color: #40b9b6;
    border-radius: 10px;
    span {
      font-family: "Poppins" !important;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #40b9b6;
    border-color: #40b9b6;
  }
}
.recipee-details-popover {
  .ant-popover-arrow-content {
    --antd-arrow-background-color: #ecf8f0;
  }
  .ant-popover-inner {
    background-color: #ecf8f0;
  }
}
.food-card-wrapper-selected {
  @media only screen and (min-width: 960px) {
    // background: rgba(61, 183, 108, 0.1);
    background: rgba(64, 185, 182, 0.0784313725);
  }
}

.ingredients-contain {
  font-size: 10px;
  color: grey;
}

.ingredients-missing {
  font-size: 10px !important;
  color: red;
}
