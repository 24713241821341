@use "../../../master.scss";
@use "../../../variables.scss";

.profile-wrapper {
  padding: 30px 50px;
  background: #fff;
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 42%;
  border-radius: 20px;

  .ant-upload-list-text {
    display: none !important;
  }

  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 500px) {
    height: 100%;
    margin-top: 0;
    padding: 85px 10px;
    border-radius: 0;
    position: unset;
  }
  @media only screen and (max-width: 1250px) {
    width: 60%;
  }
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    border-bottom: 1px solid rgba(36, 61, 76, 0.2);
    padding-bottom: 10px;
    h4 {
      font-size: 35px;
      font-weight: 600;
      color: #243d4c;
      margin: 0;
    }
  }
  .profile-pic-col {
    display: flex;
    justify-content: center;
  }
  .profile-pic-wrapper {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .profile-image {
      border: 3px solid #40b9b6;
      border-radius: 100px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 148px;
      width: 148px;
      border-radius: 100%;

      img {
        border-radius: 100%;
      }
    }
    .edit-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 38px;
      width: 38px;
      cursor: pointer;
    }
  }
  .profile-form-col {
    display: flex;
    justify-content: center;
    .profile-form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 80%;
      h4 {
        font-size: 20px;
        margin: 0;
        font-weight: 600;
        color: #243d4c;
        width: 100%;
        text-align: left;
      }

      .PhoneInput {
        padding: 10px 15px;
        font-size: 16px;
        background: #fff;
        border: 1px solid #d9d9d9;
        transition: all 0.3s;
        position: relative;
        width: 100%;
        border-radius: 12px;
        input {
          user-select: none;
          border: none;
        }
      }
      .ant-form-item-has-error .PhoneInput {
        background: #fff;
        border-color: #ff4d4f;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        display: none;
      }

      .ant-form-item {
        width: 100%;
      }

      .ant-input-affix-wrapper {
        // padding: 10px 15px;
        padding: 0px 11px !important;
        border-radius: 12px;
        font-size: 16px;
      }
      .ant-input {
        padding: 10px 15px;
        border-radius: 12px;
        font-size: 16px;
      }
      .ant-input::placeholder {
        font-family: "Poppins" !important;
        font-size: 16px;
      }
      .ant-input:hover {
        border-color: #40b9b6;
      }
      .ant-input:focus,
      .ant-input-focused {
        border-color: #40b9b6;
      }
      .ant-input-password {
        border-radius: 12px;
        font-size: 16px;
      }
      .ant-input-password::placeholder {
        font-family: "Poppins" !important;
        font-size: 16px;
      }
      .ant-input-password:hover {
        border-color: #40b9b6;
      }
      .ant-input-password:focus,
      .ant-input-affix-wrapper-focused {
        border-color: #40b9b6;
      }
      label {
        color: #170f49;
        font-size: 15px;
        font-weight: 400;
      }
      .form-seperator {
        height: 1px;
        background-color: rgba(36, 61, 76, 0.2);
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
  .react-ripples {
    width: 100%;
  }
  .profile-btn {
    background: #40b9b6;
    color: #fff;
    width: 100%;
    border-color: #40b9b6;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .profile-btn:active,
  .profile-btn:focus,
  .profile-btn:hover {
    background: #40b9b6;
    border-color: #40b9b6;
  }
}
