.foodie-content-wrapper {
  width: 69%;
  padding: 35px 40px;
  background: #fff;
  position: fixed;
  margin-top: 80px;
  margin-right: 15px;
  right: 0;
  height: 85vh;
  border-radius: 20px;
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    width: 100%;
    position: unset;
    margin-right: 0px;
  }
  @media only screen and (max-width: 1130px) {
    margin-right: 8px;
  }
  .loader-cont {
    display: flex;
    padding-top: 200px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 80vh;
  }
  .details-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    // z-index: 999999;
    span {
      // width: 100% !important;
    }
  }
  @media only screen and (max-width: 960px) {
    height: 100vh;
    margin-top: 0;
    padding: 85px 15px;
    border-radius: 0;
  }
  .selected-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .added-item-wrapper {
    padding: 5px;
    position: relative;
    height: fit-content;
    width: fit-content;
  }
  .added-item {
    background-color: #40b9b6;
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 12px;
    p {
      margin: 0;
      font-size: 14px;
      color: #fff;
    }
  }
  .del-icon-wrapper {
    border: 1.5px solid #fff;
    background-color: #40b9b6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .top-row {
    border-bottom: 1px solid rgba(36, 61, 76, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
    .ant-btn-primary,
    .ant-btn {
      width: 100%;
      padding: 19px 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      font-weight: 500;
      background-color: #40b9b6;
      border-color: #40b9b6;
      border-radius: 10px;
      span {
        font-family: "Poppins" !important;
        line-height: 24px;
        font-weight: 500;
      }
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background: #40b9b6 !important;
      border-color: #40b9b6;
    }
  }
  .title {
    h4 {
      font-size: 35px;
      font-family: "Poppins" !important;
      font-weight: 600;
      @media only screen and (max-width: 600px) {
        font-size: 25px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 18px;
      }
    }
  }
  .content-filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #243d4c;
    img {
      width: 29px;
      height: 30px;
      cursor: pointer;
    }

    &_inputWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      max-width: 465px;
      width: 100%;

      &-input {
        background: #ffffff;
        border: 1px solid rgba(36, 61, 76, 0.2);
        border-radius: 10px;
        width: 100%;
        padding: 17px 25px !important;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #243d4c;
        outline: none !important;

        &:active {
          border: 1px solid rgba(36, 61, 76, 0.2) !important;
          outline: none !important;
        }

        &:hover {
          border: 1px solid rgba(36, 61, 76, 0.2) !important;
          outline: none !important;
        }

        &:focus {
          border: 1px solid rgba(36, 61, 76, 0.2) !important;
          outline: none !important;
        }
      }

      img {
        position: absolute;
        right: 10px;
      }
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 10px !important;
      min-height: 50px;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      font-family: "Poppins" !important;
      font-size: 16px;
      font-weight: 400;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select:hover,
    .ant-select-selector:hover,
    .ant-select-selector:focus {
      border-color: #40b9b6 !important;
    }
    .ant-select-arrow {
      color: #243d4c;
    }
  }
  .selected-filters-wrapper-list {
    display: flex;
    position: relative;
    .selected-filter {
      background-color: #40b9b6;
      color: #fff;
      margin-top: 5px;
      padding: 5px 15px;
      border-radius: 8px;
    }
    img {
      position: absolute;
      right: 0;
      margin-right: 15px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 2px solid #fff;
    }
  }
  .clear-btn-wrapper {
    border-left: 1px solid rgba(61, 183, 108, 0.3);
    padding: 5px 5px 5px 10px;
    margin-left: 6px;
  }
  .clear-btn {
    background-color: transparent;
    color: #40b9b6;
    border-radius: 10px;
    padding: 0 25px;
    border-color: #40b9b6;
  }
  .clear-btn:hover {
    border-color: #40b9b6;
    color: #40b9b6;
  }
  .content-popover {
    position: relative;
    padding: 10px;
    // @media only screen and (max-width: 960px) {
    //   display: none;
    // }
    .popover-cancel {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  .responsive-1 {
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .responsive-2 {
    display: none;
    @media only screen and (max-width: 991px) {
      display: inline !important;
    }
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
  .responsive-3 {
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }
}
.foodie-content-wrapper::-webkit-scrollbar {
  display: none;
}

.filter-clear-btn {
  border: 1px solid #40b9b6;
  border-radius: 8px;
  background-color: #fff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  color: #40b9b6 !important;
}
