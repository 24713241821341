@use "../../master.scss";
@use "../../variables.scss";

.modal {
  border-radius: 25px;
  overflow: hidden;

  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    color: #170f49;
    font-size: 15px;
    font-weight: 400;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    display: block;
  }

  .modal-footer {
    text-align: right;
    margin-top: 20px;

    button {
      display: inline-block;
      margin: 5px;
      background: master.$theme-color;
      color: #fff;
      font-size: 13px;
      padding: 6px 25px;
      border-radius: 5px;
      border: 1px solid master.$theme-color;
      cursor: pointer;
      transition: 0.4s all;

      &:hover {
        background: master.$theme2-color;
        border: 1px solid master.$theme2-color;
      }

      &.close {
        background: transparent;
        border: 1px solid master.$theme-color;
        color: master.$theme-color;

        &:hover {
          border: 1px solid master.$theme-color;
        }
      }
    }
  }

  //   ***************************

  .modal-wrapper {
    .login-wrapper {
      &_title {
        // font-family: variables.$theme2-family-dmsans !;
        font-size: 24px;
        color: #170f49;
      }
      &_desc {
        color: #6f6c90 !important;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #d9dbe9;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      .login-rows-wrapper {
        .email-wrapper {
          &_label {
            color: #170f49;
            font-size: 15px;
            font-weight: 400;
          }
          &_input {
            border-radius: 8px;
          }
          .ant-input:hover {
            border-color: #ecf8f0 !important;
          }
          .ant-input:focus {
            border-color: #d9dbe9;
            box-shadow: 0 0 0 1px #59b76d !important;
          }
        }
        .password-wrapper {
          &_label {
            color: #170f49;
            font-size: 15px;
            font-weight: 400;
          }
          &_input {
            border-radius: 8px;
          }
          .ant-input-affix-wrapper-focused {
            border-color: #d9dbe9;
            box-shadow: 0 0 0 1px #59b76d !important;
          }
          .ant-input-affix-wrapper:hover {
            border-color: #ecf8f0 !important;
          }
        }
        .forgot-pass-wrapper {
          h6 {
            color: #6f6c90;
            font-size: 10px;
            font-weight: 400;
            cursor: pointer;
          }
        }
        .btn-login {
          margin-top: 15px;
          margin-bottom: 5px;
          width: 100% !important;
          background-color: #40b9b6;
          border-color: #40b9b6;
          border-radius: 10px;
          padding: 18px 0;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dont-have-acc {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          strong {
            color: #170f49;
            font-size: 14px;
            font-weight: 400;
          }
          b {
            color: #170f49;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
