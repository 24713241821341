@use "../../../master.scss";
@use "../../../variables.scss";

.reset-password-wrapper {
  padding: 30px 40px;
  background: #ecf8f0;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  border-radius: 20px;
  @media only screen and (max-width: 1250px) {
    width: 50%;
  }
  @media only screen and (max-width: 960px) {
    width: 60%;
    top: 10%;
  }
  @media only screen and (max-width: 600px) {
    height: 100vh;
    margin-top: 0;
    padding: 85px 10px;
    border-radius: 0;
    position: unset;
    width: 100%;
  }
  .forgot-password-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    border-bottom: 1px solid rgba(36, 61, 76, 0.2);
    padding-bottom: 10px;
    h4 {
      font-size: 35px;
      font-weight: 600;
      color: #243d4c;
      margin: 0;
      @media only screen and (max-width: 760px) {
        font-size: 30px;
      }
    }
  }
  .forgot-password-heading-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 35px;
      font-weight: 600;
      color: #243d4c;
      margin: 0;
      @media only screen and (max-width: 760px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 350px) {
        text-align: left;
      }
    }
    p {
      width: 60%;
      text-align: center;
      color: #6f6c90;
      font-size: 14px;
      @media only screen and (max-width: 760px) {
        font-size: 12px;
        width: 100%;
      }
      @media only screen and (max-width: 350px) {
        text-align: left;
      }
    }
  }
  .profile-pic-wrapper {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .profile-image {
      border: 3px solid #40b9b6;
      border-radius: 100px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .edit-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 38px;
      width: 38px;
      cursor: pointer;
    }
  }
  .profile-form-col {
    display: flex;
    justify-content: center;
    .profile-form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 90%;
      h4 {
        font-size: 20px;
        margin: 0;
        font-weight: 600;
        color: #243d4c;
        width: 100%;
        text-align: left;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        display: none;
      }

      .ant-form-item {
        width: 100%;
      }
      .ant-input {
        padding: 10px 15px;
        border-radius: 12px;
        font-size: 16px;
      }
      .ant-input::placeholder {
        font-family: "Poppins" !important;
        font-size: 16px;
      }
      .ant-input:hover {
        border-color: #40b9b6;
      }
      .ant-input:focus,
      .ant-input-focused {
        border-color: #40b9b6;
      }
      .ant-input-password {
        border-radius: 12px;
        font-size: 16px;
      }
      .ant-input-password::placeholder {
        font-family: "Poppins" !important;
        font-size: 16px;
      }
      .ant-input-password:hover {
        border-color: #40b9b6;
      }
      .ant-input-password:focus,
      .ant-input-affix-wrapper-focused {
        border-color: #40b9b6;
      }
      label {
        color: #170f49;
        font-size: 15px;
        font-weight: 400;
      }
      .form-seperator {
        height: 1px;
        background-color: rgba(36, 61, 76, 0.2);
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
  .profile-btn {
    background: #40b9b6;
    color: #fff;
    width: 100%;
    border-color: #40b9b6;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .profile-btn:active,
  .profile-btn:focus,
  .profile-btn:hover {
    background: #40b9b6;
    border-color: #40b9b6;
  }
}
