.meals-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(36, 61, 76, 0.1);
  flex-direction: column;
  padding-bottom: 22px;
  h4 {
    color: #243d4c;
    font-family: "Poppins" !important;
    font-size: 20px;
    font-weight: 600;
  }
  .meals-options {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    .meals-options-items {
      background-color: #f0f2f4;
      border-radius: 20px;
      padding: 5px 15px;
      width: max-content;
      cursor: pointer;
      span {
        font-size: 12px;
        font-family: "Poppins" !important;
        color: #788796;
      }
    }
    .meals-options-items-active {
      background-color: #40b9b6;
      span {
        color: #fff;
      }
    }
    .meals-options-selected {
      width: 100%;
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }
  }
  .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 5px;
    background-color: #f0f2f4;
    border-radius: 5px;
    span {
      font-family: "Poppins" !important;
      color: #788796;
      font-size: 12px;
    }
  }
  .ant-checkbox-wrapper::after {
    display: none;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #40b9b6;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #40b9b6;
  }
  .ant-checkbox-inner {
    border-color: #000;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid #40b9b6;
    background: #40b9b6;
  }
  .ant-checkbox {
    top: 0;
  }
}
