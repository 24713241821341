@use "../../master.scss";
@use "../../variables.scss";

.add-collection-modal {
  border-radius: 25px;
  overflow: hidden;

  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 30px;
      @media only screen and (max-width: 960px) {
        padding: 30px 15px;
      }
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .ant-row {
      width: 90%;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .title {
    font-size: 20px;
    color: #243d4c;
    margin-bottom: 2px;
    font-weight: 600;
    display: block;
    width: 45%;
    text-align: center;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
  }
  .collection-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(36, 61, 76, 0.2);
    border-radius: 12px;
    .collection-option-image {
      width: 20%;
    }
    .collection-option-info {
      h4 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        color: #243d4c;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #243d4c;
      }
    }
  }
  .collection-option-active {
    border: 2px solid #40b9b6;
  }
  .add-btn-wrapper {
    margin-top: 10px;
    width: 100%;
    button {
      width: 100%;
    }
    .react-ripples {
      width: 100%;
    }
  }
  .add-modal-btn {
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
  }
}
