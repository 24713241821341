@use "../../master.scss";
@use "../../variables.scss";

.create-recipe-modal {
  border-radius: 25px;
  overflow: hidden;
  // max-height: 100vh;
  // overflow-y: auto;

  .ant-upload-list-text {
    display: none !important;
  }

  .image-form-item {
    margin-bottom: 2px !important;
  }
  .ant-upload-list-picture-card-container {
    margin: 8px 8px 8px 0;
  }
  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-content {
    background-color: #ebfaf9;
    .ant-modal-body {
      padding-top: 30px;
      position: relative;
      min-height: 500px;
      @media only screen and (max-width: 960px) {
        max-height: 300px;
        padding: 30px 0;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      border-color: transparent;
    }
    .ant-modal-header {
      border-bottom: 0px;
      background-color: #ebfaf9 !important;
    }
  }
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .ant-row {
      width: 85%;
    }
  }
  .recipe-form {
    .ant-row {
      width: 100%;
      overflow-y: auto;
    }
  }
  .ingred-form {
    border-bottom: 1px solid #d9dbe9;
  }
  .step1-form {
    @media only screen and (max-width: 960px) {
      height: 165px;
      overflow-y: auto;
    }
    @media only screen and (max-width: 600px) {
      height: 95px;
      overflow-y: auto;
    }
  }
  .step3-1-form {
    @media only screen and (max-width: 960px) {
      height: 165px;
      overflow-y: auto;
    }
  }
  .step3-form {
    @media only screen and (max-width: 960px) {
      height: 110px;
      overflow-y: auto;
    }
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .title {
    font-size: 20px;
    color: #243d4c;
    margin-bottom: 2px;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;
  }
  .suggestion-btn-container {
    border-top: 1px solid #d9dbe9;
    padding-top: 20px;
    width: 80% !important;
    margin: 0;
  }
  .next-modal-btn {
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
    border-radius: 10px;
    border-color: #40b9b6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #40b9b6;
    padding: 20px 20px;
  }
  .back-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Poppins" !important;
    border-radius: 10px;
    border-color: #40b9b6;
    color: #40b9b6;
    background-color: transparent;
    padding: 20px 20px;
  }
  .back-modal-btn:hover,
  .back-modal-btn:focus {
    background-color: transparent;
    color: #40b9b6;
    border-color: #40b9b6;
  }
  .next-modal-btn:hover,
  .next-modal-btn:focus {
    background-color: #40b9b6;
    color: #fff;
    border-color: #40b9b6;
  }
  .del-icon-wrapper {
    border: 1.5px solid #fff;
    background-color: #40b9b6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    cursor: pointer;
  }
  .added-suggestion {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;
    min-height: 100px;
    @media only screen and (max-width: 960px) {
      max-height: 126px;
      overflow-y: auto;
    }
  }
  .added-item-wrapper {
    padding: 5px;
    position: relative;
    height: fit-content;
  }
  .added-item {
    background-color: #40b9b6;
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 12px;
    p {
      margin: 0;
      font-size: 14px;
      color: #fff;
    }
  }
  .suggestion-content-wrapper {
    border-top: 1px solid #d9dbe9;
    padding-top: 25px;
    margin-top: 23px;
    h4 {
      font-size: 24px;
      color: #170f49;
      font-weight: 600;
      margin: 0;
    }
    p {
      color: #6f6c90;
      font-size: 14px;
    }
  }
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 10px !important;
      min-height: 50px;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      font-family: "Poppins" !important;
      font-size: 16px;
      font-weight: 400;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select:hover,
    .ant-select-selector:hover,
    .ant-select-selector:focus {
      border-color: #40b9b6 !important;
    }
    .ant-select-arrow {
      color: #243d4c;
    }
  }
  .ant-input {
    padding: 10px 15px;
    border-radius: 12px;
    font-size: 16px;
  }
  .ant-input::placeholder {
    font-family: "Poppins" !important;
    font-size: 16px;
  }
  .ant-input:hover {
    border-color: #40b9b6;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #40b9b6;
  }
  .ant-steps-item-title::after {
    width: 90px;
    height: 10px;
    border-radius: 10px;
    top: 10px;
    background-color: #40b9b6 !important;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #40b9b6;
    border-color: #40b9b6;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    font-family: "Helvetica" !important;
    font-size: 14px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #6f6c90;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #40b9b6;
    border-color: #40b9b6;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-vertical {
    flex-direction: row;
  }
  .ant-steps-vertical
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
  .image-dragger-wrapper {
    background-color: #fff;
    padding: 13px 14px;
    border: 1px solid rgba(36, 61, 76, 0.2);
    border-radius: 10px;
    margin-bottom: 15px;
    max-height: 200px;
    overflow-y: auto;
  }
  .image-dragger-wrapper::-webkit-scrollbar {
    display: none;
  }
  .image-dragger {
    border: 1px dashed #40b9b6 !important;
    border-radius: 10px !important;
    p {
      color: #243d4c !important;
      font-weight: 500;
      font-size: 15px !important;
    }
  }
  .image-dragger:hover {
    border-color: #40b9b6 !important;
  }
  .ingredients-item {
    background: rgba(61, 183, 108, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 5px 12px 26px;
    border: 1px solid #40b9b6;
    border-radius: 8px;
    width: 100%;
    p {
      margin: 0;
      font-size: 16px;
      color: #fff;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 8%;
    }
  }
}
.steps-item {
  background: rgba(61, 183, 108, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 5px 12px 26px;
  border: 1px solid #40b9b6;
  border-radius: 8px;
  width: 100%;
  margin-top: 15px;
  p {
    margin: 0;
    font-size: 16px !important;
    color: #fff !important;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 8%;
    @media only screen and (max-width: 960px) {
      width: 15%;
    }
    @media only screen and (max-width: 500px) {
      width: 20%;
    }
  }
  .ant-upload-list {
    overflow: visible;
  }
}
.create-recipe-modal::-webkit-scrollbar {
  display: none;
}
