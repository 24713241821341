.comment-box-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border: 1px solid rgba(36, 61, 76, 0.1);
	border-radius: 12px;
	height: fit-content;
	.title {
		padding: 9px 14px;
		border-bottom: 2px solid rgba(36, 61, 76, 0.1);
		margin-bottom: 0 !important;
		h3 {
			font-family: "Poppins" !important;
			margin: 0;
		}
	}
	.comment-content {
		display: flex;
		flex-direction: column;
		gap: 15px;
		border-bottom: 2px solid rgba(36, 61, 76, 0.1);
		@media only screen and (min-width: 960px) {
			max-height: 300px;
			overflow-y: auto;
		}
		.comment-item {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
		* {
			font-family: "Poppins" !important;
		}
		padding: 20px 17px;
		.commenter-info {
			display: flex;
			width: 100%;
			align-items: flex-start;
			gap: 6px;

			img {
				height: 40px;
				width: 40px;
				border-radius: 100%;
			}
		}
		.commenter-name {
			h5 {
				font-size: 14px;
				font-weight: 500;
				margin: 0;
			}
			p {
				font-size: 10px;
				color: rgba(36, 61, 76, 0.7);
				margin: 0;
			}
		}
		.date {
			font-size: 10px;
			color: rgba(36, 61, 76, 0.7);
			margin-top: 5px;
		}
		.comment-text {
			font-size: 12px;
			font-weight: 400;
		}
		.comment-images {
			display: flex;
			gap: 10px;
			overflow-x: scroll;
			img {
				width: 81px;
				height: 51px;
				border-radius: 6px;
			}
		}
		.comment-images::-webkit-scrollbar {
			display: none;
		}
	}
	.comment-content::-webkit-scrollbar {
		display: none;
	}
	.ant-input-affix-wrapper {
		border: none;
		border-radius: 12px;
	}
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		box-shadow: none;
	}
	.ant-input::placeholder {
		color: #243d4c;
	}
}
